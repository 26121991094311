import React, { useState, useEffect, useRef } from 'react';

import { arrowRedoOutline } from 'ionicons/icons';
import ChatBotRisp from '../../services/chatbot';
import '../../theme/chatbot.css';
import {
  IonAvatar,
  IonRow,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonList,
  IonFooter,
  IonPage,
  IonGrid,
  IonCol,
  IonIcon,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
} from '@ionic/react';

const ChatBot = () => {
  const [text, setText] = useState('');
  const [messages, setMessages] = useState([]);
  const [suggestions] = useState([]);
  const [subcategoriesForCategory, setSubcategoriesForCategory] = useState(null);
  const [loading, setLoading] = useState(false);
  const messagesEndRef = useRef(null);

  const handleInputChange = (event) => {
    setText(event.target.value);
  };

  const updateScrollbar = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTo({
        top: messagesEndRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  };

  const handleSubmit = async () => {
    if (text.trim() === '') return;

    setLoading(true);
    const userMessage = { sender: 'user', text };

    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setText('');
    try {
      let resp = await ChatBotRisp.risposta(text);

      setTimeout(() => {
        if (Array.isArray(resp)) {
          resp.forEach((service) => {
            const botMessage = {
              sender: 'bot',
              text: service.response,
              link: service.link,
              suggestions: service.suggestions,
            };

            setMessages((prevMessages) => [...prevMessages, botMessage]);
          });
        } else {
          const botMessage = {
            sender: 'bot',
            text: resp.response,
            suggestions: Array.isArray(resp.suggestions) ? resp.suggestions : [],
          };

          setMessages((prevMessages) => [...prevMessages, botMessage]);
        }
        updateScrollbar();
      }, 1500);
    } catch (error) {
      console.error("Errore durante l'invio al backend:", error);
    }
    setLoading(false);
  };

  const handleCategorySelect = (category) => {
    setLoading(true);
    const selectedCategory = messages.find(
      (message) => message.suggestions && message.suggestions.some((s) => s.card.title === category)
    );

    if (selectedCategory) {
      const subcategoriesForCategory = selectedCategory.suggestions.find((s) => s.card.title === category);
      setSubcategoriesForCategory(subcategoriesForCategory.card);
    }
    const userMessage = { sender: 'user', text: category };

    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setLoading(false);
  };

  const handleCategorySelection = async (subcategory, postback) => {
    if (!suggestions || suggestions.length === 0) {
      setSubcategoriesForCategory(null);
    }
    setLoading(true);
    const userMessage = { sender: 'user', text: subcategory };

    setMessages((prevMessages) => [...prevMessages, userMessage]);
    try {
      let resp = await ChatBotRisp.risposta(subcategory);

      setTimeout(() => {
        if (Array.isArray(resp)) {
          resp.forEach((service) => {
            const botMessage = {
              sender: 'bot',
              text: service.response,
              link: service.link,
              suggestions: service.suggestions,
            };
            setMessages((prevMessages) => [...prevMessages, botMessage]);
          });
        } else {
          const botMessage = {
            sender: 'bot',
            text: resp.response,
            suggestions: resp.suggestions,
          };
          setMessages((prevMessages) => [...prevMessages, botMessage]);
        }
      }, 1500);
    } catch (error) {
      console.error("Errore durante l'invio al backend:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    const scrollToBottom = () => {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
      }
    };

    requestAnimationFrame(scrollToBottom);
  }, [messages]);

  return (
    <>
      <IonPage>
        <IonCard className="chatPhone position">
          <IonCardHeader>
            <IonRow class="ion-align-items-center ion-justify-content-center">
              <IonCol size="4">
                <img src="/assets/robot_02.png" alt="assistente virtuale" />
                {/* <IonIcon icon={chatbubbleEllipses} className="ionColor" size="large" /> */}
              </IonCol>
              <IonCol>
                <IonCardTitle>Assistente virtuale</IonCardTitle>
              </IonCol>
            </IonRow>
          </IonCardHeader>

          <IonCardContent ref={messagesEndRef} className="chatFlex">
            <IonItem lines="none">
              <IonAvatar slot="start">
                {/* <img src="/assets/iconV2/person.svg" className="ionBgColor" /> */}
                <img src="/assets/robot_02.png" alt="assistente virtuale" />
              </IonAvatar>
              <IonLabel
                className="bot-message message-container"
                style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
              >
                Ciao sono il tuo assistente virtuale, come ti posso aiutare?
              </IonLabel>
            </IonItem>

            {messages.map((message, index) => (
              <IonList key={index}>
                <IonItem lines="none">
                  {message.sender !== 'user' && (
                    <IonAvatar slot="start">
                      <img src="/assets/robot_02.png" alt="assistente virtuale" />
                      {/* <img src="/assets/iconV2/person.svg" className="ionBgColor" /> */}
                    </IonAvatar>
                  )}
                  <IonLabel
                    style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                    className={`message-container ${message.sender === 'user' ? 'user-message' : 'bot-message'}`}
                  >
                    {message.text}
                    {message.link && (
                      <a
                        href={message.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          display: 'inline-flex',
                          alignItems: 'center',
                          marginLeft: '8px',
                          color: 'blue',
                          textDecoration: 'none',
                        }}
                      >
                        Link
                      </a>
                    )}
                  </IonLabel>
                  {message.sender === 'user' && (
                    <IonAvatar slot="end">
                      <img src="/assets/iconV2/person.svg" alt="persona" />
                    </IonAvatar>
                  )}
                </IonItem>
                {message.suggestions && (
                  <IonItem lines="none" className="suggestions-container">
                    <div
                      className="suggestions"
                      style={{ display: 'flex', gap: '8px', flexWrap: 'wrap', marginTop: '8px' }}
                    >
                      {message.suggestions.map((suggestion, suggestionIndex) => (
                        <IonButton
                          key={suggestionIndex}
                          className="p-2 bg-blue-500 text-white rounded-lg bg-color bot-message"
                          onClick={() => handleCategorySelect(suggestion.card.title)}
                        >
                          {suggestion.card.title}
                        </IonButton>
                      ))}
                    </div>
                  </IonItem>
                )}
              </IonList>
            ))}

            {subcategoriesForCategory?.buttons && (
              <IonItem lines="none" className="suggestions-container">
                <div
                  className="suggestions"
                  style={{ display: 'flex', gap: '8px', flexWrap: 'wrap', marginTop: '8px' }}
                >
                  {Object.entries(subcategoriesForCategory.buttons).map(([subcategory, details], index) => (
                    <IonButton
                      key={index}
                      className="bg-color bot-message"
                      onClick={() => handleCategorySelection(subcategory, details.text)}
                    >
                      {subcategory}
                    </IonButton>
                  ))}
                </div>
              </IonItem>
            )}
            {loading && (
              <IonItem lines="none">
                <IonAvatar>
                  <img className="rotate" src="/assets/spinner-solid.svg" alt="caricamento" />
                </IonAvatar>
              </IonItem>
            )}
          </IonCardContent>

          <IonFooter className="ion-padding">
            <IonGrid>
              <IonRow className="ion-align-items-center">
                <IonCol>
                  <IonInput value={text} placeholder="Scrivi qui" onIonChange={handleInputChange}></IonInput>
                </IonCol>
                <IonCol size="auto">
                  <IonButton color="light" onClick={handleSubmit}>
                    <IonIcon icon={arrowRedoOutline} slot="icon-only" />
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonFooter>
        </IonCard>
      </IonPage>
    </>
  );
};
export default ChatBot;
