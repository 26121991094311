import React, { Component } from 'react';
import { helpCircleOutline as help, arrowForwardOutline as forward } from 'ionicons/icons';
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonPage,
  IonToolbar,
  IonButtons,
  IonList,
  IonBackButton,
  withIonLifeCycle,
  IonButton,
  IonIcon,
  IonLabel,
  IonItem,
} from '@ionic/react';

import ShopService from '../services/shopservice';

class ServiceList extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.files = React.createRef();
    this.pickedDateRef = React.createRef('');
    this.selectedTimeRef = React.createRef('');
    this.selectedStaffIdRef = React.createRef('');
    this.noteRef = React.createRef('');
    this.date = React.createRef('');
    this.serviceRef = React.createRef('');
    this.profileRef = React.createRef('');

    this.state = {
      services: [],
      shopServiceModal: false,
      shopModal: false,
      availableDate: [],
      pickedDateTime: null,
      isModalOpen: false,
      isActive: '',
      calendarDayProgression: [],
      category: [],
      selectedService: [],
      shopServices: [],
      selectedShopService: null,
      profile: null,
      shop_service_id: null,
      id: null,
    };

    this.setService = this.setService.bind(this);
    this.textHandler = this.textHandler.bind(this);
    this.requestService = this.requestService.bind(this);
  }

  async ionViewWillEnter() {
    await this.setService(this.props.match.params.id, false);

    try {
      const { id } = this.props.match.params;
      let response = await ShopService.find(id);
      const { category } = response.data;
      const { services, shop_service } = response.data.data;

      this.setState({
        services,
        shopServices: shop_service,
        category,
        id,
      });
    } catch (e) {
      console.error(e);
    } finally {
      this.props.loader.current.dismiss();
    }
  }

  async ionViewWillLeave() {
    this.setState({ isModalOpen: false });
    this.setState({ pickedDateTime: null });
    this.setState({ availableDate: null });
    this.setState({ isActive: '' });
    this.setState({ calendarDayProgression: [] });

    this.serviceRef.current = '';
    this.profileRef.current = '';
    this.selectedTimeRef.current = '';
    this.pickedDateRef.current = '';
    this.selectedStaffIdRef.current = '';
    this.noteRef.current = '';
  }

  async textHandler(event) {
    await this.setState({
      [event.target.name]: event.detail.value,
    });
  }

  async setService(id) {
    const selectedService = this.state.services.find((service) => service.id === id);
    this.setState({ selectedService, shopServiceModal: true });
  }

  async requestService(e) {
    const shop_service_id = this.state.selectedShopService.id;
    const note = this.state.note;
    const selectedService = this.state.selectedShopService;

    try {
      let shopservice = {
        shop_service_id: shop_service_id,
        shop_service_name: selectedService.name,
        note: note,
      };

      await ShopService.create(shopservice);
      this.setState({
        shop_service_id: undefined,
        note: '',
        shopServiceModal: false,
      });

      this.props.toast.current.add({
        message: `Richiesta inviata correttamente`,
      });
    } catch (error) {
      console.error(error);
      this.props.toast.current.add({
        message: `Impossibile inviare la richiesta`,
        color: 'warning',
      });
    }
  }

  formatDate(date) {
    return new Date(date).toLocaleDateString('it-IT');
  }

  async setServicePrenotabile(id) {
    const selectedShopService = this.state.shopServices.find((service) => service.id === id);

    if (!selectedShopService) {
      console.error('Servizio non trovato con ID:', id);
      return;
    }

    this.setState({
      selectedShopService,
      shopModal: true,
    });
  }
  reset() {
    this.pickedDateRef.current = '';
    this.setState({ pickedDateTime: null });
    this.setState({ isActive: '' });
  }

  render() {
    const { services, shopServices, id } = this.state;

    return (
      <IonPage>
        <>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonBackButton defaultHref="/app/home" />
              </IonButtons>
              <IonTitle>Prenota un Servizio</IonTitle>
              <IonButtons slot="end">
                <IonButton href="/app/help/Services">
                  <IonIcon icon={help} />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>

          <IonContent>
            <p className="ion-padding-top ion-padding-start ion-padding-end">
              Ecco l'elenco dei nostri servizi, <br />
              seleziona il servizio che ti interessa
            </p>
            <IonList lines="full" className="ion-no-margin ion-no-padding">
              <IonItem>Servizi prenotabili</IonItem>
              {services.map((service) => (
                <IonItem
                  key={service.id}
                  onClick={() => this.props.history.push(`/app/service/category/${id}/${service.id}`)}
                >
                  <IonLabel>{service.name}</IonLabel>
                  <IonIcon slot="end" icon={forward} />
                </IonItem>
              ))}

              {shopServices.map((shopService, i) => {
                return (
                  <IonItem
                    key={i}
                    onClick={() => this.props.history.push(`/app/service/category/${id}/shop/${shopService.id}`)}
                  >
                    <IonLabel>{shopService.name}</IonLabel>
                    <IonIcon slot="end" icon={forward} />
                  </IonItem>
                );
              })}
            </IonList>
          </IonContent>
        </>
      </IonPage>
    );
  }
}

export default withIonLifeCycle(ServiceList);
