import React, { Component } from 'react';
import { helpCircleOutline as help } from 'ionicons/icons';
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonPage,
  IonToolbar,
  IonButtons,
  IonList,
  IonBackButton,
  IonTextarea,
  withIonLifeCycle,
  IonButton,
  IonIcon,
  IonLabel,
  IonItem,
} from '@ionic/react';

import ShopService from '../services/shopservice';
import ProfileService from '../services/profile';
class ServiceDetails extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.files = React.createRef();
    this.pickedDateRef = React.createRef('');
    this.selectedTimeRef = React.createRef('');
    this.selectedStaffIdRef = React.createRef('');
    this.noteRef = React.createRef('');
    this.date = React.createRef('');
    this.serviceRef = React.createRef('');
    this.profileRef = React.createRef('');

    this.state = {
      availableDate: [],
      pickedDateTime: null,
      isActive: '',
      calendarDayProgression: [],
      selectedService: null,
      shopServices: [],
      selectedServiceShop: null,
      profile: null,
      shop_service_id: null,
      id: null,
    };

    this.textHandler = this.textHandler.bind(this);
    this.requestService = this.requestService.bind(this);
  }

  async ionViewWillEnter() {
    try {
      const { id, service_id, shopService_id } = this.props.match.params;

      let response = await ShopService.find(id);
      const { services, shop_service } = response.data.data;
      let selectedService = null;
      let selectedServiceShop = null;

      if (service_id) {
        selectedService = services.find((service) => service.id === Number(service_id)) || null;
      } else {
        selectedServiceShop = shop_service.find((service) => service.id === Number(shopService_id)) || null;
        try {
          let {
            data: { data: profile },
          } = await ProfileService.me();

          await this.setState({ profile });
        } catch (error) {
          console.error(error);
        }
      }

      this.setState({
        services,
        shopServices: shop_service,
        selectedService,
        selectedServiceShop,
        id,
      });
    } catch (e) {
      console.error(e);
    } finally {
      this.props.loader.current.dismiss();
    }
  }

  async ionViewWillLeave() {
    this.setState({ isModalOpen: false });
    this.setState({ pickedDateTime: null });
    this.setState({ availableDate: null });
    this.setState({ isActive: '' });
    this.setState({ calendarDayProgression: [] });

    this.serviceRef.current = '';
    this.profileRef.current = '';
    this.selectedTimeRef.current = '';
    this.pickedDateRef.current = '';
    this.selectedStaffIdRef.current = '';
    this.noteRef.current = '';
  }

  async textHandler(event) {
    await this.setState({
      [event.target.name]: event.detail.value,
    });
  }

  async requestService(e) {
    const shop_service_id = this.state.selectedServiceShop.id;
    const note = this.state.note;
    const selectedService = this.state.selectedServiceShop;

    try {
      let shopservice = {
        shop_service_id: shop_service_id,
        shop_service_name: selectedService.name,
        note: note,
      };

      await ShopService.create(shopservice);
      this.setState({
        shop_service_id: undefined,
        note: '',
        shopServiceModal: false,
      });

      this.props.toast.current.add({
        message: `Richiesta inviata correttamente`,
      });
    } catch (error) {
      console.error(error);
      this.props.toast.current.add({
        message: `Impossibile inviare la richiesta`,
        color: 'warning',
      });
    }
  }

  reset() {
    this.pickedDateRef.current = '';
    this.setState({ pickedDateTime: null });
    this.setState({ isActive: '' });
  }

  render() {
    const { selectedService, selectedServiceShop, profile, id } = this.state;

    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref={`/app/service/category/${id}`} />
            </IonButtons>
            <IonTitle>Servizio Selezionato</IonTitle>
            <IonButtons slot="end">
              <IonButton href="/app/help/Services">
                <IonIcon icon={help} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          {selectedService ? (
            <>
              <IonItem>
                <p>{selectedService.name}</p>
              </IonItem>
              <div className="ion-padding" dangerouslySetInnerHTML={{ __html: selectedService.description }} />
              <IonToolbar>
                <IonList>
                  <IonItem
                    key={selectedService.id}
                    lines="full"
                    detail
                    onClick={() => {
                      this.setState({ shopServiceModal: false });
                      this.props.history.push(`/app/reservations/${selectedService.id}`);
                    }}
                  >
                    <IonLabel slot="start">Prezzo</IonLabel>
                    {!!selectedService.show_price && <IonLabel slot="end">€ {selectedService.price}</IonLabel>}
                  </IonItem>
                </IonList>
              </IonToolbar>
            </>
          ) : selectedServiceShop ? (
            <>
              <IonItem>
                <p>{selectedServiceShop.name}</p>
              </IonItem>
              <div className="ion-padding" dangerouslySetInnerHTML={{ __html: selectedServiceShop.description }} />
              {profile ? (
                <>
                  <IonItem>
                    <p>
                      Sarai ricontattato al numero di telefono {profile.phone_number} oppure alla email {profile.email}{' '}
                      che hai inserito nel &nbsp;
                      <IonButton
                        color="light"
                        size="small"
                        onClick={() => {
                          this.setState({ shopServiceModal: false, shop_service_id: undefined });
                          this.props.history.push(`/app/profile`);
                        }}
                      >
                        tuo profilo
                      </IonButton>
                    </p>
                    <IonLabel position="stacked">Note aggiuntive</IonLabel>
                    <IonTextarea
                      type="text"
                      name="note"
                      placeholder="Indica qui se hai richieste particolari da inviare al Farmacista"
                      value={this.state.note}
                      onIonChange={this.textHandler}
                    />
                  </IonItem>
                  <IonButton
                    expand="block"
                    color="success"
                    onClick={this.requestService}
                    className="ion-margin-horizontal"
                    disabled={!profile}
                  >
                    Richiedi informazioni
                  </IonButton>
                </>
              ) : (
                <IonToolbar>
                  <IonButton
                    className="ion-margin-horizontal"
                    expand="block"
                    color="light"
                    onClick={() => {
                      this.setState({ shopServiceModal: false, shop_service_id: undefined });
                      this.props.history.push(
                        `/auth/login?redirect=/app/service/category/${id}/shop/${selectedServiceShop.id}`
                      );
                    }}
                  >
                    Accedi per richiedere informazioni
                  </IonButton>
                </IonToolbar>
              )}
            </>
          ) : (
            <p>Nessun servizio selezionato.</p>
          )}
        </IonContent>
      </IonPage>
    );
  }
}

export default withIonLifeCycle(ServiceDetails);
