import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import { IonApp, IonRouterOutlet, IonBadge } from '@ionic/react';

import { IonReactRouter } from '@ionic/react-router';

import { IonIcon, IonLabel, IonTabBar, IonTabButton, IonTabs } from '@ionic/react';

import {
  homeOutline as home,
  personCircleOutline as person,
  cartOutline as cart,
  calendarOutline as calendar,
  keyOutline as login,
  pricetagsOutline as promotions,
} from 'ionicons/icons';

// import AuthResource from './resources/Auth';
// import CartResource from './resources/Cart';

import PublicRoute from './components/PublicRoute';
import PrivateRoute from './components/PrivateRoute';

import HomePage from './pages/HomePage';
import CartPage from './pages/CartPage';
import CheckoutPage from './pages/Checkout/CheckoutPage';

import GestPayPage from './pages/Payment/GestPayPage';
import NexiPage from './pages/Payment/NexiPage';
import PayPalPage from './pages/Payment/PayPalPage';

import PaymentEndPage from './pages/Payment/PaymentEndPage';
import SearchPage from './pages/SearchPage';
import CategoryPage from './pages/CategoryPage';
import ProductPhoto from './pages/ProductPhoto';
import Galenica from './pages/Galenica';
import ShopServices from './pages/ShopServices';
import ShopServicesInfo from './pages/ShopServiceInfo';
import ShopServiceGroup from './pages/ShopServiceGroup';
import PrescriptionSelect from './pages/Prescription/PrescriptionSelect';
import PrescriptionPage from './pages/Prescription/PrescriptionPage';
import ProductPage from './pages/ProductPage';
import AddressesPage from './pages/AddressesPage';
import AddressPage from './pages/AddressPage';
import ReferrerPage from './pages/ReferrerPage';
import FiscalcodesPage from './pages/FiscalcodesPage';
import FiscalcodePage from './pages/FiscalcodePage';

import OrdersPage from './pages/OrdersPage';
import OrderPage from './pages/OrderPage';
import ProfilePage from './pages/ProfilePage';
import SettingsPage from './pages/SettingsPage';
import FAQPage from './pages/FAQPage';
import PromotionsPage from './pages/PromotionsPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import ResetPassword from './pages/ResetPassword';
import PrivacyCheckPage from './pages/PrivacyCheckPage';
import PrivacyPage from './pages/PrivacyPage';
import TosPage from './pages/TosPage';
import ThankyouPage from './pages/ThankyouPage';
import OrderConfirmedPage from './pages/OrderConfirmedPage';

import ReservationsPage from './pages/ReservationsPage';
import ReservationPage from './pages/ReservationPage';
import ReservationEvent from './pages/ReservationEvent';

import ShopEvent from './pages/ShopEvent';
import EventDetails from './pages/EventDetails';
import ServiceDetails from './pages/ServiceDetails';
import ServiceList from './pages/ServiceList';

import FidelityCardPage from './pages/FidelityCard/FidelityCardPage';
// import FidelityCardsPage from './pages/FidelityCard/FidelityCardsPage';
// import FidelityCardPage from './pages/FidelityCard/ManyFidelityCardPage';

import OfflinePage from './pages/OfflinePage';

//import { logoWhatsapp } from 'ionicons/icons';
import OptionService from './services/option';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

import SystemResource from './resources/System';
import AuthResource from './resources/Auth';
import CartResource from './resources/Cart';

import TrackingService from './services/tracking';

import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

export default class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      productsNumber: 0,
      reservationsNumber: 0,
      isOnline: true,
      toast: [],
      chatDisabled: true,
      promotions: 0,
      showcart: true,
      showreservation: true,
      checkprivacy: false,
      options: null,
    };
  }

  async componentDidMount() {
    TrackingService.view();
    history.listen(() => {
      TrackingService.view();
    });

    const updateCart = async () => {
      if (!!!(await AuthResource.isLogged)) {
        return;
      }

      let cart = await CartResource.current();

      if (!!cart.data) {
        this.setState({
          productsNumber:
            cart.data.products.reduce((sum, el) => (sum += el.quantity), 0) +
            cart.data.prescriptions.length +
            cart.data.product_requests.length,
        });
      }
    };

    try {
      let [
        {
          value: {
            data: { value: promotions },
          },
        },
      ] = await Promise.allSettled([OptionService.get('promotions')]);

      promotions = JSON.parse(promotions);

      await this.setState({ promotions: promotions.length });
    } catch (error) {}

    AuthResource.on('auth:login', updateCart);
    AuthResource.on('auth:logout', async () => {
      await this.setState({ productsNumber: undefined });
    });

    AuthResource.on('auth:unauthorized', async () => {
      await this.setState({ productsNumber: undefined });
    });

    CartResource.on('cart:update', updateCart);
    try {
      updateCart();
    } catch (error) {}

    SystemResource.activeCheck();
    SystemResource.on('system:version', () => window.location.reload());
    SystemResource.on('system:online', () => this.setState({ isOnline: true }));
    SystemResource.on('system:offline', () => this.setState({ isOnline: false }));

    SystemResource.on('privacycheck:on', () => this.setState({ checkprivacy: true }));
    SystemResource.on('privacycheck:off', () => this.setState({ checkprivacy: false }));

    await SystemResource.privacyCheck();
  }

  render() {
    const { isOnline } = this.state;

    if (!isOnline) {
      return (
        <IonApp>
          <OfflinePage />
        </IonApp>
      );
    }

    const { checkprivacy } = this.state;

    if (checkprivacy) {
      return (
        <IonApp>
          <IonReactRouter history={history}>
            <IonRouterOutlet>
              <PublicRoute path="/app/privacy" component={PrivacyPage} exact={true} />
              <PublicRoute path="/app/tos" component={TosPage} exact={true} />
              <PublicRoute component={PrivacyCheckPage} />
            </IonRouterOutlet>
          </IonReactRouter>
        </IonApp>
      );
    }

    return (
      <IonApp>
        <IonReactRouter history={history}>
          <IonTabs>
            <IonRouterOutlet>
              <PublicRoute path="/auth/login" component={LoginPage} exact={true} />
              <PublicRoute path="/auth/reset/:token" component={ResetPassword} exact={true} />
              <PublicRoute path="/app/home" component={HomePage} exact={true} />
              <PublicRoute path="/app/search" component={SearchPage} exact={true} />

              <PublicRoute path="/app/category" component={CategoryPage} exact={true} />
              <PublicRoute path="/app/category/:category1" component={CategoryPage} exact={true} />
              <PublicRoute path="/app/category/:category1/:category2" component={CategoryPage} exact={true} />
              <PublicRoute
                path="/app/category/:category1/:category2/:category3"
                component={CategoryPage}
                exact={true}
              />
              <PublicRoute
                path="/app/category/:category1/:category2/:category3/:category4"
                component={CategoryPage}
                exact={true}
              />

              <PublicRoute path="/app/services" component={ShopServices} exact={true} />
              <PublicRoute path="/app/serviceinfo/:id" component={ShopServicesInfo} exact={true} />
              <PublicRoute path="/app/services/:id" component={ShopServices} exact={true} />
              <PublicRoute path="/app/service_group/:id" component={ShopServiceGroup} exact={true} />
              {/* eventi */}
              <PublicRoute path="/app/events" component={ShopEvent} exact={true} />
              <PublicRoute path="/app/events/:id/:data" component={EventDetails} exact={true} />
              <PublicRoute path="/app/service/category/:id" component={ServiceList} exact={true} />
              <PublicRoute path="/app/service/category/:id/:service_id" component={ServiceDetails} exact={true} />
              <PublicRoute
                path="/app/service/category/:id/shop/:shopService_id"
                component={ServiceDetails}
                exact={true}
              />
              <PublicRoute
                path="/app/reservations/event/:id/:data/:serviceId"
                component={ReservationEvent}
                exact={true}
              />
              <PublicRoute path="/app/reservations/:id" component={ReservationPage} exact={true} />
              {/*  */}
              <PublicRoute path="/app/promotions" component={PromotionsPage} exact={true} />
              <PublicRoute path="/app/faq" component={FAQPage} exact={true} />
              <PublicRoute path="/app/privacycheck" component={PrivacyCheckPage} exact={true} />
              <PublicRoute path="/app/privacy" component={PrivacyPage} exact={true} />
              <PublicRoute path="/app/tos" component={TosPage} exact={true} />
              <PublicRoute path="/app/product/:code" component={ProductPage} exact={true} />

              <PrivateRoute path="/app/user" component={UserPage} exact={true} />
              <PrivateRoute path="/app/referrer" component={ReferrerPage} exact={true} />
              <PrivateRoute path="/app/addresses" component={AddressesPage} exact={true} />
              <PrivateRoute path="/app/address" component={AddressPage} exact={true} />
              <PrivateRoute path="/app/address/:id" component={AddressPage} exact={true} />
              <PrivateRoute path="/app/fiscalcodes" component={FiscalcodesPage} exact={true} />
              <PrivateRoute path="/app/fiscalcode" component={FiscalcodePage} exact={true} />
              <PrivateRoute path="/app/fiscalcode/:id" component={FiscalcodePage} exact={true} />
              <PrivateRoute path="/app/profile" component={ProfilePage} exact={true} />
              <PrivateRoute path="/app/orders" component={OrdersPage} exact={true} />
              <PrivateRoute path="/app/orders/:id" component={OrderPage} exact={true} />
              <PrivateRoute path="/app/settings" component={SettingsPage} exact={true} />
              <PrivateRoute path="/app/cart" component={CartPage} exact={true} />
              <PrivateRoute path="/app/checkout" component={CheckoutPage} exact={true} />
              <PrivateRoute path="/app/payment-end" component={PaymentEndPage} exact={true} />

              <PrivateRoute path="/app/reservations" component={ReservationsPage} exact={true} />

              <PrivateRoute
                path="/app/reservations/:path/:eventId/:serviceId"
                component={ReservationPage}
                exact={true}
              />

              <PrivateRoute path="/app/payment/nexi/:id" component={NexiPage} exact={true} />
              <PrivateRoute path="/app/payment/gestpay/:id" component={GestPayPage} exact={true} />
              <PrivateRoute path="/app/payment/paypal/:id" component={PayPalPage} exact={true} />

              <PrivateRoute path="/app/thankyou/:id" component={ThankyouPage} exact={true} />
              <PrivateRoute path="/app/order-confirmed/:id" component={OrderConfirmedPage} exact={true} />
              <PrivateRoute path="/app/productphoto" component={ProductPhoto} exact={true} />
              <PrivateRoute path="/app/productphoto/:id" component={ProductPhoto} exact={true} />
              <PrivateRoute path="/app/galenica" component={Galenica} exact={true} />
              <PrivateRoute path="/app/galenica/:id" component={Galenica} exact={true} />
              <PrivateRoute path="/app/prescription" component={PrescriptionSelect} exact={true} />
              <PrivateRoute path="/app/prescription/fse" component={PrescriptionPage} exact={true} />
              <PrivateRoute path="/app/prescription/nre" component={PrescriptionPage} exact={true} />
              <PrivateRoute path="/app/prescription/file" component={PrescriptionPage} exact={true} />
              <PrivateRoute path="/app/prescription/vetnre" component={PrescriptionPage} exact={true} />
              <PrivateRoute path="/app/prescription/vetfile" component={PrescriptionPage} exact={true} />
              <PrivateRoute path="/app/prescription/:id" component={PrescriptionPage} />

              <PrivateRoute path="/app/fidelityCard" component={FidelityCardPage} />
              {/* <PrivateRoute path="/app/fidelityCards" component={FidelityCardsPage} />
              <PrivateRoute path="/app/fidelityCard/:id" component={ManyFidelityCardPage} /> */}

              <PublicRoute path="/app/offline" component={OfflinePage} exact={true} />

              <Redirect from="/app" to="/app/home" exact={true} />
              <Redirect from="/" to="/app/home" exact={true} />
            </IonRouterOutlet>

            <IonTabBar slot="bottom">
              <IonTabButton tab="home" href="/app/home">
                <IonIcon icon={home} />
                <IonLabel>Home</IonLabel>
              </IonTabButton>

              {this.state.showcart > 0 && (
                <IonTabButton tab="cart" href="/app/cart">
                  <IonIcon icon={cart} />
                  <IonLabel>Carrello</IonLabel>
                  {!!this.state.productsNumber ? <IonBadge>{this.state.productsNumber}</IonBadge> : null}
                </IonTabButton>
              )}
              {this.state.showreservation > 0 && (
                <IonTabButton tab="reservations" href="/app/reservations">
                  <IonIcon icon={calendar} />
                  <IonLabel>Prenotazioni</IonLabel>
                  {!!this.state.reservationsNumber ? <IonBadge>{this.state.reservationsNumber}</IonBadge> : null}
                </IonTabButton>
              )}
              {this.state.promotions > 0 && (
                <IonTabButton tab="promotions" href="/app/promotions">
                  <IonIcon icon={promotions} />
                  <IonLabel>Offerte </IonLabel>
                </IonTabButton>
              )}

              {!!AuthResource.token ? (
                <IonTabButton tab="user" href="/app/user">
                  <IonIcon icon={person} />
                  <IonLabel>Il mio profilo</IonLabel>
                </IonTabButton>
              ) : (
                <IonTabButton tab="login" href="/auth/login">
                  <IonIcon icon={login} />
                  <IonLabel>Accedi </IonLabel>
                </IonTabButton>
              )}
            </IonTabBar>
          </IonTabs>
        </IonReactRouter>
      </IonApp>
    );
  }
}
