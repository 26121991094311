import React, { Component } from 'react';
import SystemResource from '../resources/System';
import HeaderButtons from '../components/HeaderButtons';
import { withIonLifeCycle } from '@ionic/react';
import moment from 'moment';
import { IonHeader, IonToolbar, IonBackButton, IonContent, IonTitle, IonButtons, IonItem } from '@ionic/react';

import Calendar from '../components/Calendar';
import ReservationsService from '../services/reservations';
class ReservationPage extends Component {
  constructor(props) {
    super(props);

    this.loader = React.createRef();

    this.state = {
      momentDate: moment(),
      service: [],
    };
  }

  async ionViewWillEnter() {
    if (!!this.props.loader) this.props.loader.current.show();

    try {
      const { id } = this.props.match.params;
      const response = await ReservationsService.current(id);
      const { service } = response.data;
      this.setState({ service });
      SystemResource.checkVersion();
    } catch (error) {
      console.error(error);
    } finally {
      this.props.loader.current.dismiss();
    }
  }

  render() {
    const { momentDate, service } = this.state;

    const handleNextMonth = () => {
      let momentClone = momentDate.clone().add(1, 'M');

      if (momentClone.format('YYMM') <= moment().add(2, 'M').format('YYMM')) {
        this.setState({ momentDate: momentDate.add(1, 'M') });
      }
    };

    const handlePrevMonth = () => {
      let momentClone = momentDate.clone().subtract(1, 'M');

      if (momentClone.format('YYMM') >= moment().format('YYMM')) {
        this.setState({ momentDate: momentDate.subtract(1, 'M') });
      }
    };

    return (
      <>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/app/services" />
            </IonButtons>
            <HeaderButtons {...this.props} />
            <IonTitle>Prenotazione </IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonItem>
            <p>{service.name}</p>
          </IonItem>
          <div className="ion-padding" dangerouslySetInnerHTML={{ __html: service.description }} />

          <Calendar
            momentDate={momentDate}
            serviceId={this.props.match.params.id}
            nextMonth={handleNextMonth}
            prevMonth={handlePrevMonth}
            {...this.props}
          />
        </IonContent>
      </>
    );
  }
}

export default withIonLifeCycle(ReservationPage);
