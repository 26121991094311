import axios from './axios';

import auth from '../resources/Auth';

export default {
  risposta: async (text) => {
    let url = `${process.env.REACT_APP_API_URL}/chatbot`;

    try {
      const response = await axios.get(url, {
        params: { message: text },
        headers: { Authorization: `Bearer ${auth.token}` },
      });

      return response.data;
    } catch (error) {
      console.error('Errore durante la richiesta:', error);
      throw error;
    }
  },
};
