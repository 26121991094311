import React, { Component } from 'react';
import { helpCircleOutline as help, arrowForwardOutline as forward } from 'ionicons/icons';
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonButton,
  IonIcon,
  IonList,
  IonItem,
  IonLabel,
  withIonLifeCycle,
} from '@ionic/react';
import ShopServiceService from '../services/shopservice';

class Services extends Component {
  constructor(props) {
    super(props);

    this.form = React.createRef();
    this.files = React.createRef();

    this.state = {
      shop_service_id: undefined,
      note: '',
      services: [],
      shopServices: [],
      selectedService: null,
      selectedShopService: null,
    };

    this.setService = this.setService.bind(this);
  }

  async ionViewWillEnter() {
    this.setState({
      id: this.props.id,
      profile: undefined,
      note: '',
      services: [],
      shopServices: [],
    });

    try {
      const response = await ShopServiceService.get();
      const { data } = response.data;

      this.setState({
        services: data,
      });
    } catch (error) {
      console.error(error);
    }

    if (!!this.props.match.params.id) {
      await this.setService(this.props.match.params.id * 1);
    }
  }

  async setService(id) {
    const selectedService = this.state.services.find((service) => service.id === id);
    this.setState({ selectedService });

    this.props.history.push(`/app/service/category/${id}`);
  }

  render() {
    const { services } = this.state;

    return (
      <>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/app/home" />
            </IonButtons>
            <IonTitle>Prenota un Servizio</IonTitle>
            <IonButtons slot="end">
              <IonButton href="/app/help/Services">
                <IonIcon icon={help} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <p className="ion-padding-top ion-padding-start ion-padding-end">
            Sfoglia l'elenco delle categorie <br /> e seleziona quella di tuo interesse per accedere ai relativi
            servizi.
          </p>
          <IonList lines="full" className="ion-no-margin ion-no-padding">
            <IonItem>Categorie</IonItem>
            {services.map((service, i) => {
              return (
                <IonItem key={i} onClick={() => this.setService(service.id)}>
                  <IonLabel>{service.name}</IonLabel>
                  <IonIcon slot="end" icon={forward} />
                </IonItem>
              );
            })}
          </IonList>
        </IonContent>
      </>
    );
  }
}

export default withIonLifeCycle(Services);
