import { useState } from 'react';
import OptionService from '../../services/option';

const useOption = () => {
  const [options, setOptions] = useState();
  const [slides, setSlides] = useState([]);
  // const [optionsLoading, setOptionsLoading] = useState()
  // const [optionsError, setOptionsError] = useState()

  const loadOptions = async () => {
    try {
      // setOptionsLoading(true)
      const resp = await OptionService.allClean();
      setOptions(resp.data.data);
      if (resp.data.data && resp.data.data.slides_image) {
        const parsedSlides = JSON.parse(resp.data.data.slides_image);
        setSlides(parsedSlides);
      }
    } catch (error) {
      // setOptionsError(error)
    }
    // finally {
    //     setOptionsLoading(false)
    // }
  };
  // return [options, optionsLoading, optionsError, loadOptions]
  return [options, slides, loadOptions];
};

export default useOption;
