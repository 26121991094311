import axios from './axios';

export default {
  //ritorna una struttura dati cosi: {data: {value: ... }}
  get: async (key, def) => {
    if (key === 'privacycheck') {
      let url = `${process.env.REACT_APP_API_URL}/option/${key}`;

      let data = await axios.get(url);

      return data.data;
    } else {
      let url = `${process.env.REACT_APP_API_URL}/option/${key}`;
      try {
        return await axios.get(url);
      } catch (error) {
        return !!def ? { data: { value: def } } : error;
      }
    }
  },

  all: async () => {
    let url = `${process.env.REACT_APP_API_URL}/options`;
    return await axios.get(url);
  },

  allClean: async () => {
    let url = `${process.env.REACT_APP_API_URL}/optionsClean`;
    return await axios.get(url);
  },
};
