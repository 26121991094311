import React, { Component } from 'react';

import { IonContent, IonHeader, IonTitle, IonToolbar, IonButtons, IonBackButton } from '@ionic/react';

// import HeaderButtons from '../components/HeaderButtons';
import OptionService from '../services/option';
import ChatBot from '../components/Home/ChatBot';

export default class FAQPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      privacy: '',
    };
  }
  async ionViewWillEnter() {
    try {
      let [
        {
          value: {
            data: { value: faq },
          },
        },
      ] = await Promise.allSettled([OptionService.get('faq')]);
      this.setState({ faq });
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    return (
      <>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/" />
            </IonButtons>

            {/* <HeaderButtons {...this.props} />   */}

            <IonTitle>Domande frequenti</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent className="ion-margin ion-padding">
          <ChatBot />
          <div dangerouslySetInnerHTML={{ __html: this.state.faq }} />
        </IonContent>
      </>
    );
  }
}
