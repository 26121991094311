import React, { useState } from 'react';
import { useIonViewWillEnter } from '@ionic/react';
import HomeHeader from '../components/Home/HomeHeader';
// import HomeImg from '../components/Home/HomeImg';
import CertEcommerce from '../components/Home/CertEcommerce';
import useOptions from '../hooks/Shared/useOptions';
import useShops from '../hooks/Home/useShops';
import SystemResource from '../resources/System';
import ShopsService from '../services/shops';
import { IonContent, IonGrid, IonRow, useIonToast } from '@ionic/react';
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle } from '@ionic/react';
import { IonCol, IonButton } from '@ionic/react';
import { IonSlides, IonSlide } from '@ionic/react';
import ReservationsService from '../services/reservations';
import OrderService from '../services/order';
import ProfileService from '../services/profile';

import { IonIcon, IonTabButton } from '@ionic/react';
import {
  time,
  logoWhatsapp as whatsapp,
  call,
  list,
  medkit,
  calendarClear,
  calendarNumber,
  chatbubbleEllipses,
} from 'ionicons/icons';

const HomePage = (props) => {
  const [shops, selected, loadShops] = useShops();
  const [options, slides, loadOptions] = useOptions();
  const version = `${SystemResource.apiversion}-${process.env.REACT_APP_VERSION}`;
  const { history } = props;
  const { slot } = props;
  const [present, dismiss] = useIonToast();
  const [reservations, setReservations] = useState([]);
  const [orders, setOrders] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const select_service = options?.select_service ? JSON.parse(options.select_service) : [];

  useIonViewWillEnter(() => {
    props.loader.current.show();
    loadOptions();
    loadShops();
    getReservation();
    getOrders();
    checkLoginStatus();
    props.loader.current.dismiss();
  });

  const openCall = async () => {
    if (!!options?.call_number) {
      window.open(`tel:${options?.call_number}`, '_system');
    }
  };

  const openChat = async () => {
    if (!!options?.whatsapp_number && !!options?.whatsapp_text) {
      window.open(`https://wa.me/${options?.whatsapp_number}?text=${encodeURI(options?.whatsapp_text)}`, '_system');
    }
  };

  const saveShop = (val) => {
    const shop_id = { shop_id: val };

    const loadData = async () => {
      try {
        await ShopsService.update(shop_id);
        toast('Punto vendita aggiornato');
      } catch (error) {
        toast('Errore, non è stato possibile aggiornare il punto vendita associato');
      }
    };

    if (selected !== undefined && val !== selected) {
      loadData();
    }
  };

  const toast = (mess) =>
    present({
      buttons: [{ text: 'X', handler: () => dismiss() }],
      message: mess,
      duration: 1000,
    });

  const getReservation = async () => {
    try {
      const response = await ReservationsService.all();
      let reservations = response.data;
      setReservations(reservations);
    } catch (error) {
      console.error(error);
    }
  };

  const getOrders = async () => {
    try {
      const response = await OrderService.get();
      let orders = response.data.data;
      setOrders(orders);
    } catch (error) {
      console.error(error);
    }
  };

  const truncateText = (text, wordLimit = 8) => {
    if (!text) return '';
    const words = text.split(' ');
    return words.length > wordLimit ? words.slice(0, wordLimit).join(' ') + '...' : text;
  };

  const checkLoginStatus = async () => {
    try {
      const response = await ProfileService.me();

      if (response) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    } catch (error) {
      console.error('Errore nella verifica del login:', error);
      setIsLoggedIn(false);
    }
  };

  const slideOpts = {
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    initialSlide: 1,
    speed: 400,
  };

  return (
    <>
      <HomeHeader
        shopName={options?.shop_name}
        selected={selected}
        saveShop={saveShop}
        shops={shops}
        history={history}
        slot={slot}
      />

      <IonContent id="homepage" className="ion-no-padding">
        {/* {options?.home_image && options?.shop_name && (
          <HomeImg
            homeImage={`${options.home_image}?v=${version}`}
            shopName={options.shop_name}
            className="card-slider-home"
          />
        )} */}
        {slides && slides.length > 0 && (
          <IonSlides pager={true} options={slideOpts} className="height">
            {slides?.map((slide, index) => (
              <IonSlide key={index}>
                <IonCard color="light" className="card-slider-home card-wrapper ion-no-padding">
                  <a href={slide.url} className="image-home">
                    <img alt="Silhouette of mountains" src={slide.image} className="img-width img-max" />
                  </a>
                </IonCard>
              </IonSlide>
            ))}
          </IonSlides>
        )}
        <IonCard color="light" className="welcome-card">
          <IonCardHeader className="pd-bottom">
            <h2 className="m-top-zero"> {options?.shop_name}</h2>
          </IonCardHeader>

          <IonCardContent>
            <div className="contact-info ">
              <p className="fontP ion-no-margin">
                <span onClick={() => openCall()}>
                  <IonIcon icon={call} className="icon-medium" />
                  {options?.call_number}
                </span>
                <span onClick={() => openChat()}>
                  <IonIcon icon={whatsapp} className="icon-medium" />
                  {options?.whatsapp_number}
                </span>
              </p>

              <p className="fontP ion-no-margin">
                <IonIcon icon={time} className="icon-medium" />
                {options?.open_on}
              </p>
            </div>
          </IonCardContent>
        </IonCard>

        <IonGrid className="m-buttons m-top pd-top">
          <IonRow>
            <IonCol className="pd-right">
              <IonTabButton href="/app/prescription" size="small" className="service-item purple">
                <IonIcon icon={medkit} />
              </IonTabButton>
              <p className="fontPBotton">Ricette</p>
            </IonCol>
            <IonCol className="pd-right">
              <IonTabButton href="/app/category" className="service-item yellow">
                <IonIcon icon={list} />
              </IonTabButton>
              <p className="fontPBotton">Prodotti</p>
            </IonCol>
            <IonCol className="pd-right">
              <IonTabButton href="/app/services" className="service-item blue">
                <IonIcon icon={calendarClear} />
              </IonTabButton>
              <p className="fontPBotton">Servizi</p>
            </IonCol>
            <IonCol className={options?.chatbot === 1 ? 'pd-right' : ''}>
              <IonTabButton href="/app/events" className="service-item coral">
                <IonIcon icon={calendarNumber} />
              </IonTabButton>
              <p className="fontPBotton">Eventi</p>
            </IonCol>
            {options?.chatbot === 1 && (
              <IonCol>
                <IonTabButton href="/app/faq" className="service-item green">
                  <IonIcon icon={chatbubbleEllipses} />
                </IonTabButton>
                <p className="fontPBotton">ChatBot</p>
              </IonCol>
            )}
          </IonRow>
        </IonGrid>

        {/* Orders & Reservations */}
        <div className="section-header">
          <h2>Ordini & Prenotazioni</h2>
        </div>

        <IonGrid className="ion-no-padding ion-no-margin">
          <IonRow className="stat-card-row">
            <IonCol size="6">
              <IonCard className="stat-card yellow-bg mg-r-t">
                {isLoggedIn ? (
                  <IonRow className="card-row" onClick={() => (window.location.href = '/app/reservations')}>
                    <IonCol>
                      <h3>Prenotazioni</h3>
                    </IonCol>

                    <IonCol className="pd">
                      <IonCardContent lines="none" className="border">
                        <span>{reservations.length} Prenotazioni</span>
                      </IonCardContent>
                    </IonCol>

                    {/* <IonCol className="margin-botton">
                      <IonButton href="/app/reservations" fill="clear" color="dark" className="ion-text-center width">
                        <small>Le tue prenotazioni</small>
                      </IonButton>
                    </IonCol> */}
                  </IonRow>
                ) : (
                  <IonRow className="card-row" onClick={() => (window.location.href = '/auth/login')}>
                    <IonCol>
                      <h3>Prenotazioni</h3>
                    </IonCol>
                    <IonCol className="pd">
                      <IonCardContent lines="none" className="border">
                        <span>Accedi</span>
                      </IonCardContent>
                    </IonCol>
                    {/* <IonCol className="m-bottom">
                      <IonButton href="/auth/login" fill="clear" color="dark" className="ion-text-center width">
                        Accedi
                      </IonButton>
                    </IonCol> */}
                  </IonRow>
                )}
              </IonCard>
            </IonCol>
            <IonCol size="6">
              <IonCard className="stat-card coral-bg mg-l-t ">
                {isLoggedIn ? (
                  <IonRow className="card-row" onClick={() => (window.location.href = '/app/orders')}>
                    <IonCol>
                      <h3>Ordine in corso</h3>
                    </IonCol>
                    <IonCol className="pd">
                      <IonCardContent lines="none" className="border">
                        <span>{orders.length} Ordini</span>
                      </IonCardContent>
                    </IonCol>
                    {/* <IonCol className="margin-botton">
                      <div className="display-flex">
                        <IonButton href="/app/orders" fill="clear" color="dark" className="width">
                          <small>I tuoi ordini</small>
                        </IonButton>
                      </div>
                    </IonCol> */}
                  </IonRow>
                ) : (
                  <IonRow className="card-row" onClick={() => (window.location.href = '/auth/login')}>
                    <IonCol>
                      <h3>Ordini</h3>
                    </IonCol>
                    <IonCol className="pd">
                      <IonCardContent lines="none" className="border">
                        <span>Accedi</span>
                      </IonCardContent>
                    </IonCol>
                    {/* <IonCol className="m-bottom">
                      <IonButton href="/auth/login" fill="clear" color="dark" className="ion-text-center width">
                        Accedi
                      </IonButton>
                    </IonCol> */}
                  </IonRow>
                )}
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>

        <div className="section-header">
          <h2 className="m-top">Servizi in evidenza</h2>
        </div>
        <IonSlides pager={true} options={slideOpts}>
          {select_service.map((service) => (
            <IonSlide
              key={service.id}
              className="ion-no-padding"
              onClick={() => (window.location.href = `/app/reservations/${service.id}`)}
            >
              <IonCard color="light" className="card-slider card-wrapper">
                <IonCardTitle>
                  <h3>{service.name}</h3>
                </IonCardTitle>
                <IonCardContent className="ion-padding text">
                  <p>{truncateText(service.description.replace(/<[^>]+>/g, ''))}</p>

                  <p className="text fontP" color="dark">
                    Prezzo: {service.price} €
                  </p>
                </IonCardContent>

                <IonButton href={`/app/reservations/${service.id}`} color="dark" fill="clear">
                  Prenota !
                </IonButton>
              </IonCard>
            </IonSlide>
          ))}
        </IonSlides>
        


        {!!options?.ecommerce_link && !!options?.ecommerce_logo && (
          <CertEcommerce
            ecommerceLink={`${options?.ecommerce_link}`}
            ecommerceLogo={`${options?.ecommerce_logo}?v=${version}`}
            shopName={options?.shop_name}
          />
        )}
      </IonContent>
    </>
  );
};

export default HomePage;
